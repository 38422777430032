import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import Navbar from "./Components/Navbar";
import reportWebVitals from "./reportWebVitals";
import Body from "./Components/Body";
import NightModeContext from "./utils/NightModeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
  const [nightMode, setNightMode] = useState(false);
  return (
    <div>
      <NightModeContext.Provider value={{ nightMode, setNightMode }}>
        <Navbar />
        <Body />
      </NightModeContext.Provider>
    </div>
  );
};
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
